
html, body {
    height:100%;
    display: flex;
    flex-direction: column; 
}

body {
    /*background-color: #333333;*/
    /*background: #5050a0;*/
    background: url(../images/main-bg.jpg) repeat-y;
    background-size: cover;
    background-position: center;
}

.brand-topbar {
    background: #5050a0;
    padding: 7px;
    font-family: "Montserrat", Helvetica;
    font-size: 12px;
    font-weight: 400;    
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    margin: 0px;
}

/* .brand-topbar ul {
    margin: 0px;
    padding-inline-start: 0px;
    display: block;
}

.brand-topbar li {
    float: left;
    margin-left: 20px;
    color: #ffffff;
    list-style: none;
    text-indent: 0px;
} */

.brand-topbar a {
    color: #ffffff;
    text-decoration: none;
    white-space: nowrap;
}

.verify-form-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    padding: 20px;    
}

.verify-form-wrapper > div {
    max-width: 900px;
    width: 90%;
}

.verify-form-wrapper-title {
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
}

.centered-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    padding: 20px;    
}

.centered-wrapper-title {
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
}

.qr-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.qr-container canvas {
    margin: 25px;
}

.brand-logo-container {
    background-color: #ffffff;
    padding: 20px;
    height: 100px;
}

.brand-logo-container .logo {
    height: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.brand-logo-container-small {
    background: #ffffff;
    padding: 10px;
    height: 60px;
}

.brand-logo-container-small .logo {
    height: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.token-list-account {
    font-weight: bold;
    cursor: pointer;
}

.token-list-token {
    cursor: pointer;
}

.token-list-item {
    padding: 10px;
}

.token-list {
    height: 400px;
    overflow-y: scroll;
    padding: 10px;
}

.circc-container {
    border-radius: 50%;
    position: relative;
    margin: auto;
}

.circc-container-content {
    vertical-align: middle;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);    
}


.navbar {
    background-color: #ffffff;
    padding: 20px;
    max-height: 150px; 
    min-height: 100px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.navbar .logo {
    height: 60px;
    display: block;
}

.btn-brand {
    color: #5050a0;
}

.white-big-text {
    font-size: 2.5rem;
    color: white;
    text-align: center;
    text-shadow: 2px 2px #000000;    
}

.appstore-logo {
    height: 5rem;
    margin: 1rem;
}


.verify-form-wrapper-ver {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    padding: 20px;    
}

.verify-form-wrapper-ver > div {
    max-width: 400px;
    width: 90%;
}

.verify-form-wrapper-title-ver {
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
}
